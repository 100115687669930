
#priceContainer {
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 30px;
    padding-bottom: 30px;
}

#titleButton {
    border: 0;
    padding-bottom: 20px;
    background-color: rgba(0, 0, 0 ,0);
}

#elementRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

#priceElement {
    padding-bottom: 30px;
}

#priceTitle {
    font-size: 18px;
}

#priceRow {
    display: flex;
    align-items: center;
}

#arrow-icon {
    margin-left: 10px;
    width: 25px;
    height: 20px;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    #priceContainer {
        padding: 30px
    }
}