
/* Container */
#contactContainer{
    justify-content: space-between;
    display: flex;
    margin: 50px;
}

#contactRow {
    align-items: center;
    display: flex;
    margin-top: 20px;
}

/* Adress */
#icon {
    margin-right: 15px;
}

#contactHoursTitle {
    margin-top: 40px;
    margin-bottom: 20px;
}

/* Pin */
.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: rgb(204, 30, 30);
    position:static;
    transform: rotate(-45deg); 
  }
  
  .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: white;
    position: absolute;
    border-radius: 50%;
  }
  
@media screen and (max-width: 768px) {
    #map {
        display: none;
    }
}