
#commentsContainer {
    display: flex;
    align-items: center;
    padding-bottom: 100px;
}

#commentsTextsContainer {
    margin-right: 50px;
}

#commentsTitle {
    padding-bottom: 20px;
}

#commentsDescription {
    font-size: 20px;
}

#commentStars {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

#commentItemContainer {
    background-color: var(--dark-green);
    padding: 20px;
    border-radius: 20px;
}

#commentTitle {
    color: white;
    padding-bottom: 10px;
    text-align: center;
}

#commentDescription {
    color: white;
    text-align: end;
}

.slick-slide {
    padding: 20px;
}

#carouselContainer {
    display: flex;
    align-items: center;
}

#carouselLeftButton {
    rotate: 90deg;
    border: 0;
    background-color: rgb(0,0,0,0);
}

#carouselRightButton {
    rotate: -90deg;
    border: 0;
    background-color: rgb(0,0,0,0);
}

@media screen and (max-width: 768px) {
    #commentsContainer {
        display: inline;
    }

    #carouselContainer {
        margin-left: -15px;
        justify-content: center;
    }

    #carouselLeftButton {
        margin-right: -30px;
    }

    #carouselRightButton {
        margin-left: -30px;
    }
}
