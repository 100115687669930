
/* Navigation container */
.navigationContainer {
     display: flex;
     align-items: center;
     justify-content: space-between;
     position: relative;
     height: 90px;
     background-color: var(--light-green);
     border-bottom-left-radius: 30px;
     border-bottom-right-radius: 30px;
}

.navigationContainer ul {
    align-items: center;
    display: inline-flex;
}

.navigationContainer a {
    color: white;
}

/* Logo */
#logo a {
    color: white;
    font-size: 45px;
    margin-left: 50px;
}

/* Socials */
.socials {
    margin-right: 50px;
}

#socials a {
    text-decoration: underline;
    margin-left: 20px;
}

/* Menu mobile */
#mobileDivider {
    background-color: white;
    height: 1.5px;
    margin-top: 30px;
    margin-bottom: 30px;
}

#mobileSocials {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

#mobileMenuFooter {
    color: white;
    text-decoration: underline;
}

#mobileMenu a {
    color: white;
}

#mobileNavigationButton {
    font-size: 40px;
    color: white;
    cursor: pointer;
    padding-right: 30px;
}

#mobileMenu {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: var(--light-green);
    height: calc(100vh - 60px);
    z-index: 2;
}

#mobileMenu li {
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
    font-size: larger;
}

#menu li {
    margin-left: 50px;
    font-size: larger;
}
