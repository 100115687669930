
/* About */
#aboutImage {
    height: 70vh;
    object-fit:cover;
    aspect-ratio: 1;
}

#aboutContainer {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
}

#aboutTitle {
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;
}

#aboutDescriptionFirstLine {
    font-size: 20px;
    text-align: center;
}

#aboutDescriptionSecondLine {
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
}

#aboutTextContainer {
    margin: 30px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    #aboutImage {
        display: none;
    }
}


