
#footerConstainer {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 80px;
    padding-right: 80px;
    background-color: var(--light-green);
}

#emailContainer {
    display: flex;
    align-items: center;
}

#telefonContainer {
    display: flex;
    align-items: center;
}

#hoursContainer {
    display: flex;
    align-items: center;
}

#adressContainer {
    display: flex;
    align-items: center;
}

.footerText {
    color: white;
}

@media screen and (max-width: 768px) {
    #footerConstainer {
        display: block;
        padding-top: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #telefonContainer, #emailContainer, #hoursContainer, #adressContainer {
        margin-bottom: 40px;
        justify-content: center;
    }

    .footerText {
        text-align: center;
    }
}