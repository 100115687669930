
#worksTextContainer {
    margin-right: 30px;
    align-items: start;
    align-content: start;
    justify-content: flex-start;
}

#worksImagesHorizontalContainer {
    display: flex;
    align-items: center;
}

#worksImageSquare {
    height: 30vh;
    object-fit: cover;
    aspect-ratio: 1;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#worksImageRectangle {
    width: 35vw;
    object-fit: cover;
    aspect-ratio: 1;
}

.worksImage {
    width: 300px;
    height: 300px;
    border-width: 3;
    border-color: red;
    border-radius: 10px;
}

#worksContainer {
    display: flex;
    margin-bottom: 100px;
    align-items: center;
}

#worksButtonTitle {
    color: white;
}

#worksIcon {
    color: white;
    padding-right: 10px;
}

#worksTitle {
    margin-bottom: 20px;
}

#worksDescription {
    font-size: 20px;
}

#worksButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    #worksImageRectangle {
        display: none;
    }

    #worksContainer {
        display: block;
    }

    #worksImagesVerticalContainer {
        display: flex;
    }

    #worksImageSquare {
        margin-top: 40px;
        width: 40vw;
        height: 40vw;
    }
}