
/* Common */
.image {
    border-radius: 20px;
}

#homeContainer {
    margin: 50px;
}

@media screen and (max-width: 768px) {
    #homeContainer {
        margin: 30px;
    }
}