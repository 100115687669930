
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


:root {
    --header-height: 3rem;
    --light-green: #959D90;
    --dark-green: #1E3A2F;
    --helvetica-font: "Helvetica";
}

body {
    font-family: var(--helvetica-font);
}

h1 {
    color: var(--dark-green);
    font-family: var(--helvetica-font);
    font-size: 34px;
}

h2 {
    color: var(--dark-green);
    font-family: var(--helvetica-font);
    font-size: 28px;
}

h3 {
    color: var(--dark-green);
    font-family: var(--helvetica-font);
    font-size: 22px;
}

h4 {
    line-height: 25px;
    font-size: 16px;
}

p {
    font-size: 20px;
    color: var(--dark-green);
    line-height: 30px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.mainButton {
    background-color: var(--dark-green);
    color: white;
    border-radius: 25px;
    width: 200px;
    height: 50px;
    margin: auto;
    margin-top: 30px;
    align-content: center; 
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border: 0;
}

#divider {
    height: 1px;
    background-color: var(--dark-green);
}